import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box, Text } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img src="https://user-images.githubusercontent.com/586552/226987730-84d99d46-1dd4-4ddc-91b3-046d7eeedbad.png" role="presentation" width="960" alt="An image showing a button group in medium and small sizes." />
    <h2>{`Usage`}</h2>
    <p>{`Buttons can be grouped together as individual segments of related actions.`}</p>
    <p>{`Each segment in a button group is comprised from our default button component and can be visually represented with the same button types and states.`}</p>
    <p>{`Grouping buttons with a button group is better than rendering buttons close together for the following uses:`}</p>
    <ul>
      <li parentName="ul">{`rendering a group of buttons next to one or more buttons`}</li>
      <li parentName="ul">{`grouping multiple sets of buttons`}</li>
      <li parentName="ul">{`saving horizontal space when rendering multiple closely-related buttons`}</li>
    </ul>
    <h3>{`Best practices`}</h3>
    <ul>
      <li parentName="ul">{`Use button groups to organize similar functionality. Don't group buttons just because they're close together.`}</li>
      <li parentName="ul">{`For most use-cases, only default button types should be used in button groups. In rare cases, primary buttons can be included in button groups but there should only ever be one primary button (if any) in a button group.`}</li>
      <li parentName="ul">{`Avoid grouping too many buttons together. It could be overwhelming to the user.`}</li>
      <li parentName="ul">{`Do not use a button group to indicate a selection. Use a `}<a parentName="li" {...{
          "href": "/components/segmented-control"
        }}>{`segmented control`}</a>{` instead.`}</li>
      <li parentName="ul">{`Do not use a button group as a replacement for `}<a parentName="li" {...{
          "href": "/components/tab-nav"
        }}>{`tab navigation`}</a>{`.`}</li>
      <li parentName="ul">{`Avoid mixing buttons with text labels with icon-only buttons. However, it is acceptable to group a text-labeled button with an icon-only button with a `}<a parentName="li" {...{
          "href": "https://primer.style/foundations/icons/triangle-down-16"
        }}>{`down-pointing triangle`}</a>{` that opens a dropdown menu of actions related to the button.`}</li>
      <li parentName="ul">{`Do not group an `}<a parentName="li" {...{
          "href": "/components/button#invisible"
        }}>{`invisible`}</a>{` button with buttons of another variant.`}</li>
    </ul>
    <h2>{`Options`}</h2>
    <h3>{`Size`}</h3>
    <p>{`Button groups only support medium (default) and small button sizes.`}</p>
    <img src="https://user-images.githubusercontent.com/586552/226987738-cf95c038-8fa7-4393-bf2c-014082145556.png" role="presentation" width="960" alt="An image showing a button group in medium and small sizes." />
    <h3>{`Split buttons with a dropdown`}</h3>
    <p>{`A button group can be shown as a split button with an action on the left and dropdown button with an additional list of actions.`}</p>
    <img src="https://user-images.githubusercontent.com/586552/226987739-a7aeadc7-9bc3-40d1-ba34-beefe32525ff.png" role="presentation" width="960" alt="An image showing a button group in medium and small sizes." />
    <h3>{`Leading and trailing visuals`}</h3>
    <p>{`Similarly to buttons, button segments can optionally include an icon and/or a counter.`}</p>
    <img src="https://user-images.githubusercontent.com/586552/226987735-471711f1-5342-4605-903d-889301eace46.png" role="presentation" width="960" alt="An image showing a button group in medium and small sizes." />
    <h2>{`Accessibility`}</h2>
    <h3>{`As toolbar`}</h3>
    <p>{`By default, a button group does not behave like a `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/toolbar_role"
      }}>{`toolbar`}</a>{`, so assistive technologies still interpret the buttons as unrelated. The grouping is purely visual. `}</p>
    <p>{`If you want to make the button group behave like a toolbar, you can add the `}<inlineCode parentName="p">{`role="toolbar"`}</inlineCode>{` prop to the `}<inlineCode parentName="p">{`ButtonGroup`}</inlineCode>{` component. This will communicate the appropriate role to assistive technologies.`}</p>
    <p>{`When `}<inlineCode parentName="p">{`role="toolbar"`}</inlineCode>{` is used, individual buttons aren't separately focusable. Instead, only one button receives focus, and users switch between all buttons in the toolbar using the cursor keys. This behavior is baked into the component.`}</p>
    <p>{`Please note the toolbar keyboard interaction behaviors are currently implemented in Primer React only, and are not available in Primer View Components.`}</p>
    <h3>{`Descriptive buttons`}</h3>
    <p>{`Labeling buttons properly lets users know what will happen when they activate the control, lessens errors, and increases confidence.`}</p>
    <p>{`Read more about `}<a parentName="p" {...{
        "href": "/guides/accessibility/descriptive-buttons"
      }}>{`descriptive buttons`}</a>{`.`}</p>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="ButtonGroup" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/components/button"
          }}>{`Button`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "/components/segmented-control"
          }}>{`Segmented control`}</a></p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      